define(['$console', 'app', 'elementViewed', 'freeProductTiers'], ($console, app, elementViewed, freeProductTiers) => {

  const freeProductSelection = () => {
    const component = {};
    component.elementViewed = elementViewed;

    const _config = {
      attrib: {
        expanded: 'aria-expanded',
        skuID: 'data-sku',
        offerID: 'data-offer',
        freeGiftID: 'data-free-gift-id',
      },
      selectors: {
        itemContainer: '[data-js-element=freeProductSelection_itemContainer]',
        tierContainers: '[data-product-tier]',
        freeProductItem: '[data-product-item]',
        selectedFreeProducts: '[data-current-selected]',
        tempTotalSelected: '[data-total-selected-tiers]',
        totalSelectedCount: '[data-total-selected]',
        toggleOpen: '[data-js-element=freeProductSelection_toggleOpen]',
        viewedTitle: '[data-title-viewed]',
        variationDropdownContainer: '.freeProductSelection_item-show',
        variationDropdown: '.freeProductSelection_productVariationReplace',
      },
      classNames: {
        toggleOpenClass: 'freeProductSelection-show',
        variationDropdownShow: 'freeProductSelection_productVariationReplace-show',
      }
    };

    const _init = element => {
      component.element = element;
      if (!component.element || component.element instanceof HTMLElement === false) {
        $console.error('freeProductSelection.init was called without the expected HTMLElement');
        return;
      }

      component.viewedTitle = component.element.querySelector(component.config.selectors.viewedTitle);
      component.elementViewed(component.viewedTitle, component.triggerViewedTracking);
      component.tempTotalSelected = component.element.querySelector(component.config.selectors.tempTotalSelected);
      app.publish('tracking/record', 'freeProductSelection', 'Free Gift Appeared', 'Offer Id', component.freeGiftID);

      const toggleOpenClass = document.querySelector(`.${component.config.classNames.toggleOpenClass}`);
      if (toggleOpenClass) {
        app.publish('tracking/record', 'freeProductSelection', 'Opened Free Gift On Load', 'Offer Id', component.freeGiftID);
      }
      component.bindOpen();
      component.mapTiers();

      app.subscribe('freeProductSelection/totalSelection', component.updateSelectedTotal);
      return component;
    };

    const _bindOpen = () => {
      const sysToggleOpen = component.element.querySelector(component.config.selectors.toggleOpen);

      sysToggleOpen.addEventListener('click', component.toggleOpenSamples);
    };

    const _mapTiers = () => {
      const tierContainers = component.element.querySelectorAll(component.config.selectors.tierContainers);
      Array.from(tierContainers).map(el => {
        freeProductTiers().init(el);
      });
    };

    const _updateSelectedTotal = () => {
      const totalEl = component.element.querySelector(component.config.selectors.totalSelectedCount);
      if (!totalEl) return;
      const selectedEl = component.element.querySelectorAll(component.config.selectors.selectedFreeProducts);
      const totalSelected = Array.from(selectedEl).map(el => {
        return Number(el.getAttribute('data-current-selected'));
      }).reduce((a, b) => {
        return a + b
      });

      totalEl.setAttribute('data-total-selected', totalSelected);
      totalEl.innerText = totalSelected;
    };

    const _triggerViewedTracking = () => {
      app.publish('tracking/record', 'freeProductSelection', 'Viewed Free Gift', 'Offer ID', component.freeGiftID);
    };

    const _toggleOpenSamples = (e) => {
      e.preventDefault();
      const clickedTarget = e.currentTarget;
      const itemContainer = component.element.querySelector(component.config.selectors.itemContainer);
      const giftID = e.currentTarget.parentElement.getAttribute(component.config.attrib.freeGiftID);
      const openContainers = itemContainer.querySelectorAll(component.config.selectors.variationDropdownContainer);

      if (component.element.classList.contains(component.config.classNames.toggleOpenClass)) {
        component.element.classList.remove(component.config.classNames.toggleOpenClass);
        clickedTarget.setAttribute(component.config.attrib.expanded, 'false');

        openContainers.forEach(el => {
          const openDropdowns = el.querySelectorAll(component.config.selectors.variationDropdown);
          openDropdowns.forEach(el => el.classList.remove(component.config.classNames.variationDropdownShow));
        })

        app.publish('tracking/record', 'freeProductSelection', 'Closed Free Gift', 'Gift Id', giftID);
      } else {
        component.element.classList.add(component.config.classNames.toggleOpenClass);
        clickedTarget.setAttribute(component.config.attrib.expanded, 'true');
        openContainers.forEach(el => {
          const openDropdowns = el.querySelectorAll(component.config.selectors.variationDropdown);
          openDropdowns.forEach(el => el.classList.add(component.config.classNames.variationDropdownShow));
        })
        app.publish('tracking/record', 'freeProductSelection', 'Opened Free Gift', 'Gift Id', giftID);
      }
    };


    component.config = _config;
    component.init = _init;
    component.bindOpen = _bindOpen;
    component.mapTiers = _mapTiers;
    component.toggleOpenSamples = _toggleOpenSamples;
    component.triggerViewedTracking = _triggerViewedTracking;
    component.updateSelectedTotal = _updateSelectedTotal;


    return component;
  }

  return freeProductSelection;

});

